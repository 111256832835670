// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD0Q5VUWnuPL1TWo2-96bN6agYZiAOWiws",
    authDomain: "videocreator-8c44f.firebaseapp.com",
    projectId: "videocreator-8c44f",
    storageBucket: "videocreator-8c44f.firebasestorage.app",
    messagingSenderId: "411112708091",
    appId: "1:411112708091:web:1019558abb492ff19c9d5e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore without enabling persistence
const googleProvider = new GoogleAuthProvider();

export { auth, db, googleProvider };
