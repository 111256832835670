// src/PricingPanel.js
import React from 'react';
import { auth, googleProvider } from './firebaseConfig';
import { signInWithPopup } from 'firebase/auth';
import './PricingPanel.css';

const PricingPanel = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  // Google sign-in function
  const handleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      console.log("User signed in");
      onClose(); // Close the pricing panel after successful sign-in
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  return (
    <div className="pricing-panel-overlay">
      <div className="pricing-panel">
        <button className="close-button" onClick={onClose}>X</button>
        <h2 className="pricing-title">Choose the Right Plan for You</h2>
        <div className="pricing-tiers">
          {/* Free Plan */}
          <div className="pricing-tier">
            <h3>Free</h3>
            <p className="price">$0 <span>/month</span></p>
            <p>For Personal Use</p>
            <button className="cta-button" onClick={handleSignIn}>Sign Up, It's Free</button>
            <ul>
              <li>20 minutes of TTS generation</li>
              <li>Up to 5 scripts per month</li>
              <li>Basic voice options</li>
            </ul>
          </div>

          {/* Pro Plan */}
          <div className="pricing-tier highlighted">
            <h3>Pro</h3>
            <p className="price">$14.99 <span>/month</span></p>
            <p>For Aspiring YouTubers</p>
            <button className="cta-button">Subscribe Now</button>
            <ul>
              <li>120 minutes of TTS generation</li>
              <li>Up to 30 scripts per month</li>
              <li>Premium voice options</li>
              <li>Script customization</li>
            </ul>
          </div>

          {/* Business Plan */}
          <div className="pricing-tier">
            <h3>Business</h3>
            <p className="price">$19.99 <span>/month</span></p>
            <p>For Growing Channels</p>
            <button className="cta-button">Subscribe Now</button>
            <ul>
              <li>300 minutes of TTS generation</li>
              <li>Up to 100 scripts per month</li>
              <li>All premium voice options</li>
              <li>Advanced script customization</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPanel;
