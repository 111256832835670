// src/GoogleAuth.js
import React, { useState, useEffect } from 'react';
import { auth, googleProvider } from './firebaseConfig';
import { signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import { initializeUser } from './firestore';  // Import initializeUser function
import './GoogleAuth.css';

const GoogleAuth = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Initialize Firestore user data if it's the first sign-in
        await initializeUser(currentUser.uid, {
          email: currentUser.email,
          name: currentUser.displayName,
        });
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Call initializeUser after successful sign-in to ensure user data is created
      await initializeUser(user.uid, {
        email: user.email,
        name: user.displayName,
      });

      console.log("User signed in");
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      console.log("User signed out");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return user ? (
    <div className="user-info">
      <img src={user.photoURL} alt="User Profile" className="user-profile-pic" />
      <button onClick={handleSignOut} className="sign-out-button">Sign out</button>
    </div>
  ) : (
    <button className="google-auth-button" onClick={handleSignIn}>
      Sign in
    </button>
  );
};

export default GoogleAuth;
