// src/Header.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import GoogleAuth from './GoogleAuth';
import autovidLogo from './autovid.png';
import PricingPanel from './PricingPanel';
import './Header.css';

function Header() {
  const [isPricingOpen, setIsPricingOpen] = useState(false);

  const togglePricingPanel = () => {
    setIsPricingOpen(!isPricingOpen);
  };

  return (
    <div className="header-container">
      <header className="app-header">
        <div className="header-logo-title">
          <img src={autovidLogo} alt="AutoVid Logo" className="autovid-logo-large" />
          <span className="header-title">- Scripts & Voiceovers in Seconds</span>
        </div>
        <nav className="header-nav">
          <button className="header-button" onClick={togglePricingPanel}>Pricing</button>
          <GoogleAuth />
          <a
            href="https://www.youtube.com"
            className="header-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} size="lg" />
          </a>
        </nav>
      </header>
      {isPricingOpen && <PricingPanel isOpen={isPricingOpen} onClose={togglePricingPanel} />}
    </div>
  );
}

export default Header;
