import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import ProgressBar from './ProgressBar';
import { auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FaQuestionCircle } from 'react-icons/fa';

function App() {
  const [niche, setNiche] = useState('');
  const [keywords, setKeywords] = useState('');
  const [length, setLength] = useState('');
  const [channelName, setChannelName] = useState('');
  const [voiceChoice, setVoiceChoice] = useState(1);
  const [speedChoice, setSpeedChoice] = useState('normal');
  const [voices, setVoices] = useState([]);
  const [script, setScript] = useState('');
  const [spokenScript, setSpokenScript] = useState('');
  const [loadingScript, setLoadingScript] = useState(false);
  const [loadingVoiceover, setLoadingVoiceover] = useState(false);
  const [scriptProgress, setScriptProgress] = useState(0);
  const [voiceoverProgress, setVoiceoverProgress] = useState(0);
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const [signInPrompt, setSignInPrompt] = useState('');
  const [hasMembership, setHasMembership] = useState(false); // Mocked membership status
  const [showAdModal, setShowAdModal] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      // Mock: Here you'd check for membership status, e.g., from Firestore
      setHasMembership(false); // Assume no membership for this example
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      axios.get('http://localhost:5000/voices')
        .then(response => {
          setVoices(response.data.voices);
        })
        .catch(error => {
          console.error('Error fetching voices:', error);
          setError('Error fetching voices.');
        });
    }
  }, [user]);

  const generateScript = async (e) => {
    e.preventDefault();

    if (!user) {
      setSignInPrompt('Please sign in to generate a script.');
      return;
    }

    setLoadingScript(true);
    setScriptProgress(0);
    setError('');
    setSignInPrompt('');
    setScript('');
    setSpokenScript('');

    const interval = setInterval(() => {
      setScriptProgress((prev) => Math.min(prev + 10, 90));
    }, 500);

    try {
      const response = await axios.post('http://localhost:5000/generate-script', {
        niche,
        keywords,
        length,
        channel_name: channelName,
      });

      clearInterval(interval);
      setScriptProgress(100);
      setScript(response.data.script);
      setSpokenScript(response.data.spoken_script);
    } catch (err) {
      setError('Error generating script.');
      console.error(err);
    } finally {
      setLoadingScript(false);
      setScriptProgress(0);
    }
  };

  const handleGenerateVoiceoverClick = () => {
    if (hasMembership) {
      generateVoiceover();
    } else {
      setShowAdModal(true);
    }
  };

  const watchAdAndGenerateVoiceover = () => {
    setShowAdModal(false);
    setTimeout(() => {
      alert("Ad finished. Voiceover generation will begin.");
      generateVoiceover();
    }, 5000); // Mock a 5-second ad
  };

  const generateVoiceover = async () => {
    setLoadingVoiceover(true);
    setVoiceoverProgress(0);
    setError('');

    const interval = setInterval(() => {
      setVoiceoverProgress((prev) => Math.min(prev + 10, 90));
    }, 500);

    try {
      const response = await axios.post('http://localhost:5000/generate-voiceover', {
        spoken_script: spokenScript,
        voice_choice: voiceChoice,
        speed: speedChoice,
        channel_name: channelName,
      }, { responseType: 'blob' });

      clearInterval(interval);
      setVoiceoverProgress(100);

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'audio/mpeg' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${channelName.replace(/[^a-zA-Z0-9_]/g, "_")}_voiceover.mp3`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      setError('Error generating voiceover.');
      console.error(err);
    } finally {
      setLoadingVoiceover(false);
      setVoiceoverProgress(0);
    }
  };

  return (
    <div className="app">
      <Header />
      <div className="app-container">
        <form onSubmit={generateScript} className="form">
          <div className="form-group">
            <label>Channel Name:</label>
            <input
              type="text"
              value={channelName}
              onChange={(e) => setChannelName(e.target.value)}
              placeholder="e.g., Tech Insights"
            />
          </div>

          <div className="form-group">
            <label>Niche:</label>
            <input
              type="text"
              value={niche}
              onChange={(e) => setNiche(e.target.value)}
              required
              placeholder="e.g., Technology, Health"
            />
          </div>

          <div className="form-group">
            <label>Keywords (comma-separated):</label>
            <input
              type="text"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              required
              placeholder="e.g., AI, Machine Learning"
            />
          </div>

          <div className="form-group">
            <label>Length (minutes):</label>
            <input
              type="number"
              value={length}
              onChange={(e) => setLength(e.target.value)}
              required
              min="1"
              step="0.1"
            />
          </div>

          <div className="form-group">
            <label>Voice Choice:
              <FaQuestionCircle className="info-icon" title="Choose a voice style for the generated voiceover." />
            </label>
            <select
              value={voiceChoice}
              onChange={(e) => setVoiceChoice(parseInt(e.target.value))}
            >
              {voices.map((voice) => (
                <option key={voice.id} value={voice.id}>{voice.description}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Voice Speed:
              <FaQuestionCircle className="info-icon" title="Set the speaking speed for the voiceover." />
            </label>
            <select
              value={speedChoice}
              onChange={(e) => setSpeedChoice(e.target.value)}
            >
              <option value="slow">Slow</option>
              <option value="normal">Normal</option>
              <option value="fast">Fast</option>
            </select>
          </div>

          <button type="submit" disabled={loadingScript} className="button primary">
            {loadingScript ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              <i className="fas fa-magic"></i>
            )} Generate Script
          </button>
          {loadingScript && <ProgressBar progress={scriptProgress} />}
        </form>

        {signInPrompt && <p className="sign-in-prompt">{signInPrompt}</p>}
        {error && <p className="error">{error}</p>}

        {script && (
          <div className="script-section">
            <h2>Generated Script:</h2>
            <pre className="script-content">{script}</pre>
            <button onClick={handleGenerateVoiceoverClick} disabled={loadingVoiceover} className="button secondary">
              {loadingVoiceover ? 'Generating Voiceover...' : 'Generate Voiceover'}
            </button>
            {loadingVoiceover && <ProgressBar progress={voiceoverProgress} />}
          </div>
        )}

        {showAdModal && (
          <div className="modal">
            <div className="modal-content">
              <h3>Watch an Ad or Go Ad-Free</h3>
              <p>Watch an ad to generate the voiceover or purchase a membership for an ad-free experience.</p>
              <button onClick={watchAdAndGenerateVoiceover}>Watch Ad</button>
              <button onClick={() => window.location.href = '/membership'}>Go Ad-Free</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
