// src/firestore.js
import { db } from './firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';

export async function initializeUser(userId, userData) {
  try {
    console.log("Attempting to initialize user with ID:", userId);

    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      console.log("User document does not exist. Creating a new one.");

      await setDoc(userDocRef, {
        ...userData,
        subscription: {
          status: 'free',
          startDate: new Date().toISOString(),
          renewDate: null,
        },
        purchases: [],
        createdAt: new Date(),
      });

      console.log("User document created successfully in Firestore.");
    } else {
      console.log("User document already exists in Firestore.");
    }
  } catch (error) {
    console.error("Error in initializeUser function:", error);
  }
}
